@font-face {
    font-family: 'helvetica-rounded';
    font-style: normal;
    font-weight: bold;
    src: url(../fonts/HelveticaRoundedLTStd-Bd.ttf) format('truetype');
}

body {
    font-family: 'Helvetica-Neue', sans-serif;
}

h1, h2, h3, h4, div.mantine-Accordion-label {
    font-family: 'helvetica-rounded', sans-serif!important;
}

/** Add-Button CSS **/
button.mainButton, a.mainButton {
    padding: 8px 15px;
    background: #e11b22;
    color: #fff;
    border: 0px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    min-width: 150px;
}

button.mainButton:hover, a.mainButton:hover {
    background: #e11b22bb
}

button.altButton, a.altButton {
    padding: 8px 15px;
    background: #fff;
    color: #e11b22;
    border: 1px solid #d0d1d2;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

button.cancelButton {
    color: #a81010;
}

button.altButton:hover, a.altButton:hover {
    color: #fff;
    background: #e11b22;
    border-color: #e11b22;
}

div.addInstanceButton {
    float:right;
    margin-top: -55px;
}

div.addInstanceButton:after {
    clear: both;
    float: none;
}

/** Modal CSS **/
.popupContentWrapper {
    padding: 20px;
    min-height: 500px;
}

.popupContentWrapper button.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.popup-content {
    border-radius: 9px;
}

.popupContentWrapper .actions {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 50px;
}

.popupContentWrapper .actions button {
    min-width: 180px;
}

.popupContentWrapper .actions button:first-child {
    margin-right: 10px;
}

.popupContentWrapper .actions svg {
    margin-right: 10px;
}

/** Container & General Styling **/
p.noItemsFound {
    text-align: center;
    color: #696969;
    margin-top: 15%;
}

a.backButton {
    font-size: 14px;
    text-decoration: none;
    color: #e11b22;
    position: relative;
}

/** Message CSS **/
div.message-box {
    padding: 15px;
    background-color: #f5f7f9;
    border-radius: 6px;
    margin: 30px 0;
    border-left: 5px solid;
    overflow: auto;
}

div.message-box.info {
    border-left-color: #3884ff;
}

div.message-box.info .message-icon svg {
    color: #3884ff;
}

div.message-box.warning .message-icon svg {
    color: #9f0000;
}

div.message-box.warning {
    border-left-color: #9F0000;
}

div.message-box.success .message-icon svg {
    color: #1cc88a;
}

div.message-box.success {
    border-left-color: #1cc88a;
}

div.message-box .message-text {
    width: 90%;
    padding-right: 10px;
}

div.message-box .message-text::after {
    clear: both;
    float: none;
}

div.message-box .message-icon {
    float: left;
    height: 100%;
    margin-right: 10px;
}

div.whitespace {
    float: none;
    clear: both;
}

div.seperator25 {
    height: 25px;
}

div.seperator50 {
    height: 50px;
}

div.seperator150 {
    height: 150px;
}

div.form_left_half {
    width: 48%;
    margin-right: 2%;
    float: left;
}

div.form_right_half {
    width: 48%;
    margin-left: 2%;
    float: left;
}

div.form_right_half::after {
    clear:both;
    float: none;
}

.inliveSVG > svg {
    width: 90px;
    height: auto;
}

.mantine-Modal-title {
    font-weight: bold;
    font-family: 'helvetica-rounded';
}

.colorSchemeHeadline {
    margin-top: 25px;
    margin-bottom: 10px;
}

.addColorButton:hover {
    background: #a81010;
}

.addColorButton, .mantine-Button-root {
    background: #e11b22;
}

.mantine-Input-rightSection {
    top: unset;
    height: 40px;
}

.infoSubHeader {
    margin-top: 35px;
    margin-bottom: -20px;
}

label {
    margin-bottom: 5px;
    margin-top: 25px;
    display: block;
}

.clientName {
    display: block;
    margin-bottom: 25px;
    margin-top: -30px;
    font-size: 14px;
}

.clientLogo > svg {
    max-width: 180px;
    max-height: 120px;
    width: 100%;
    height: auto;
}

.color-spot {
    height: 120px;
    width: 120px;
    border-radius: 60px;
    margin: auto auto 15px;
    border: 1px solid #cfcece;
}

.flex-row.color-row {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 25px 0 75px 0;
}
.flex-row.color-row > div {
    min-width: 183px;
    margin: 0 25px;
}
table.color-table {
    min-width: 183px;
}

table.locationTable {
    margin-top: -15px;
    margin-bottom: 35px;
}

table.locationTable th {
    width: 50%;
}

.passwordLabel {
    float:left;
}