div.instanceContainer {
    border: 1px solid #eee;
    border-radius: 9px;
    padding: 30px;
    margin-bottom: 25px;
}

div.instances {
    margin-top: 50px;
}

div.instanceContainer table {
    width: 100%;
}

div.instanceContainer table td.instanceIcon svg {
    color: #006aff;
    width: 80px;
    height: 80px;
}

.btn-group button, .btn-group button svg, button > svg {
    margin-right: 10px;
}

table button > svg {
    margin-right: 0!important;
}

td.instanceIcon {
    width: 120px;
    text-align: center;
}

td.instanceDetails ul {
 list-style: none;
}

td.instanceDetails ul li {
    margin: 8px 0;
    color: #696969;
    font-size: 14px;
}

td.instanceDetails ul strong {
    display: inline-block;
    min-width: 180px;
}

td.instanceActions button {
    float: right;
    border: 1px solid #d0d1d2;;
    border-radius: 4px;
    padding: 10px 15px;
    background: #fff;
}

td.instanceActions button svg {
    width: 2em!important;
    height: 15px;
}

a.instanceDetailButton {
    float: right;
}


.customerInfo h3 {
    margin-top: 0px;
}

.subscriptionDetails {
    width:80%;
    float: left;
}

.subscriptionDetails h3, .subscriptionDetails p {
    margin-top: 0px;
    margin-bottom: 2px;
}


.customerDetails h3 {
    margin-top: 0px;
}

.customerDetails {
    width:80%;
    float: left;
}

.customerDetails h3, .customerDetails p {
    margin-top: 0px;
    margin-bottom: 2px;
}

.subscriptionDetails::after, .customerDetails::after {
    clear: both;
    float: none;
}

.instanceDetailWrapper {
    width: 100%;
    clear: both;
    float: none;
}

.rowSourceLang img, .rowTargetLang img {
    width: 40px;
    margin: 15px;
}
