.box {
    margin-top: 10%;
    display: table;
    border: 1px solid #ebebeb;
    border-radius: 15px;
    box-shadow: 0 15px 35px 0 rgba(60,66,87,.08),0 5px 15px 0 rgba(0,0,0,.12);
    background-color: #fff;
    padding: 56px 48px;
    width: 540px;
    min-width: 440px;
    margin: auto;
}

.login .wrapper {
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 10vw;
}

div.loginTitle > span {
    font-size: 28px;
    font-weight: bold;
    font-family: 'helvetica-rounded';
}

div.loginTitle {
    margin-bottom: 40px;
}

.submit-btn {
    background-color: #e11b22;
    border: none;
    padding: 10px 0;
    width: 100%;
    color: #fff;
    font-size: 16px;
    border-radius: 6px;
    font-weight: medium;
    cursor: pointer;
    height: 45px;
    margin-top: 50px;
}

.submit-btn:hover {
    background-color: #e11b22bb;
    opacity: 0.8;
}

input {
    border-radius: 4px;
    line-height: 2em;
    height: 30px;
    font-size: 16px;
    padding: .5rem .75rem;
    display: block;
    border: none;
    width: 100%
}

.input-wrapper {
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    width: 100%;
    border-radius: 4px;
    float: none;
    clear:both;
}

.loginError {
    color: #a81010;
    margin: 25px 0px 5px 0px;
}

span.pw-reset {
    float: right;
    color: #e11b22;
    font-weight: 500;
    cursor: pointer;
    display: block;
    margin: 25px 0 10px 0;
}

span.signIn {
    color: #e11b22;
    font-weight: 500;
    cursor: pointer;
    display: block;
}

span.pw-reset::after {
    clear: both;
}

div.legal {
    width:100%;
    text-align: center;
    margin-top: 25px;
}

div.legal a {
    font-size: 14px;
    color: #707070!important;
    text-decoration: none;
}