html, body, #root {
  height: 100%;
}

*:focus-visible {
  border-color: #e11b22;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapp-all {
  height: 100%;
  width: 100%;
}

.login {
  background-image: url("../bg.jpg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center center;
	background-size: cover !important;
}

.content-wrapper {
  height: 100%;
  max-width: 1440px;
}

.dashboard .content-wrapper {
  margin: 65px 50px 0px 250px;
  padding-top: 25px;
  padding-left: 10px;
}

div.login .content-wrapper {
  height: 90%;
  padding-top: 5%;
  padding-left: 0px!important;
  margin: auto;
}

.sidebar h3 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 15px;
}

.height150 {
  height: 150px;
}

.seperator {
  width: 90%;
  margin: 25px 0px;
  border-top: 2px solid #ebebeb;
}

.sidebar ul {
  list-style: none;
  margin-left: 0px;
  padding: 0 10px 0 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #031b4e;
  margin-bottom: 10px
}

.sidebar i {
  margin-right: 10px;
  color: #031b4e;
}

div.clear {
  clear: both!important;
  float: none!important;
}

span.seperator {
  margin: 0px 15px;
  font-size: 12px;
  color: #707070!important;
}

select {
  width: 100%;
  height: 50px;
  padding: 0px 8px;
  background: #fff;
  border: none;
  border-radius: 4px;
}

.popupContentWrapper {
	display: table;
	margin: auto;
}

.addInstanceWrapper {
	display: table-cell;
  	vertical-align: middle;
	text-align: center;
}

div.noLicense > svg.svg-inline--fa {
	font-size: 72px;
	color: #e3e6f0;
}

.project-wrapper .addNewButton, .mantine-Col-root .mainButton, .mantine-Col-root .altButton {
  margin-top: 12px;
  float: right;
}

.mantine-Tabs-tabLabel {
    font-family: 'helvetica-rounded';
    font-size: 16px;
}

.mantine-123gri0[data-active], .mantine-123gri0[data-active]:hover {
  border-color: #e11b22;
}

img.flag {
  height: 20px;
  margin: 5px 0;
}

table button.moreButton {
  min-width: 50px;
}

table button.mainButton.deleteButton {
  min-width: 50px;
  margin-left: 15px;
}

input {
  height: 40px!important;
}

table td.actionTD {
  text-align: right;
}

table.detailsTable th {
  min-width: 200px;
  text-align: left;
}

.addUserButton {
  float: right;
  margin-top: -50px;
}

.mantine-1ca9d6u {
  padding-top: 11px;
  padding-left: 35px;
  padding-right: 35px;
  max-width: 100%;
}

footer {

}

footer a {
  color: #fff;
}

.mantine-134h5mf {
  padding-top: calc(var(--mantine-header-height, 0px) + 16px);
  padding-bottom: calc(var(--mantine-footer-height, 0px) + 16px);
  padding-left: calc(var(--mantine-navbar-width, 0px) + 35px);
  padding-right: calc(var(--mantine-aside-width, 0px) + 35px);
}